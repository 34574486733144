import * as React from "react";
import { createRoot } from "react-dom/client";
import {
    createBrowserRouter,
    RouterProvider,
    Route,
    Link,
} from "react-router-dom";
import App from "./App";

const router = createBrowserRouter([
    {
        path: "/",
        element: <App />,
    },
    {
        path: "health",
        element: () => {
            window.location.href = '/health.html';
            return null;
          },
    },
]);

createRoot(document.getElementById("root")).render(
    <RouterProvider router={router} />
);

export default router;
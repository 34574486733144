import "./Loader.css";
import useCanInteractWithChat from "../utilities/useCanInteractWithChat";
import useLoaderPhrases from "../utilities/useLoaderPhrases";
import useLocale from "../utilities/useLocale";

const Loader = () => {
  const userCanInteract = useCanInteractWithChat();
  const loaderClassname = userCanInteract
    ? "loaderWrapper"
    : "loaderWrapper visible";
  let lang = useLocale();
  //Override Locale (english only for now)
  lang = "en-US";

  const phrase = "We are processing your request...";
  
  return (
    <div className={loaderClassname}>
      <div class="container">
        <div className="loadingText"></div>
        <div className="phrase-container">
          {phrase}
        </div>
        <div className="loader-container">
          <span class="loader"></span>
        </div>
      </div>
    </div>
  );
};

export default Loader;

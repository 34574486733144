import axios from 'axios'
/* eslint-disable no-unused-vars */

const getTokenFromApi = async () => {
    const res = await axios.post(process.env.REACT_APP_BOT_TOKEN_ENDPOINT, { user:{ id:process.env.REACT_APP_CHATBOT_USER_ID, name:process.env.REACT_APP_CHATBOT_USER_NAME}}, { headers:{ 'Content-Type' : 'application/json' } });
    return res.data.result;
};

const isTokenExpired = (token) => {
    const expirationDate = token.expirationDate;
    return expirationDate < new Date(Date.now());
};

export const getToken = async () => {
    // let token = localStorage.getItem('BOT_TOKEN_STORAGE_KEY'); 
    // if (!token || isTokenExpired(token)) {
      let  token = await getTokenFromApi(); //Refresh here
      token.expirationDate = new Date(Date.now()+1000*token.expiresIn);
    //   localStorage.setItem('BOT_TOKEN_STORAGE_KEY', JSON.stringify(token));
    // } else {
    //   token = JSON.parse(token);
    // }
    return token;
};

export const getNewToken = async () => {
    let token = await getTokenFromApi(); 
    token.expirationDate = new Date(Date.now()+1000*token.expiresIn);
    // localStorage.setItem('BOT_TOKEN_STORAGE_KEY', JSON.stringify(token));
    return token;
};
import "./CustomSendBox.css";
import {useState, useMemo} from "react";
import {hooks} from "botframework-webchat-component";
import useCanInteractWithChat from "../utilities/useCanInteractWithChat";
// import useLinkAsMessage from "../utilities/useLinkAsMessage";

const {useSendMessage} = hooks;

const CustomSendBox = () => {
  const [message, setMessage] = useState("");
  const sendMessage = useSendMessage();
  // useLinkAsMessage();
  const userCanInteract = useCanInteractWithChat();
  const send = () => {
    sendMessage(message);
    setMessage("");
  };

  const onChange = (e) => {
    setMessage(e.target.value);
  };

  const onKeyDown = (e) => {
    if (!userCanInteract) {
      e.preventDefault();
      return;
    }

    if (e.key === "Enter" && userCanInteract) {
      send();
    }
  };

  const buttonClassName = useMemo(() => {
    if (message.length > 0) {
      return "send-box__button active";
    } else {
      return "send-box__button";
    }
  }, [message]);

  return (
    <div className="send-box">
      <input
        type="text"
        className="send-box__input"
        placeholder="Type a message"
        value={message}
        onChange={onChange}
        onKeyDown={onKeyDown}
      />
      <button className={buttonClassName} onClick={send}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          fill="none"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          className="icon icon-tabler icon-tabler-send"
          viewBox="0 0 24 24"
        >
          <path stroke="none" d="M0 0h24v24H0z"></path>
          <path d="M10 14L21 3M21 3l-6.5 18a.55.55 0 01-1 0L10 14l-7-3.5a.55.55 0 010-1L21 3"></path>
        </svg>
      </button>
    </div>
  );
};

export default CustomSendBox;

import {useMemo} from "react";
import {hooks} from "botframework-webchat-component";

const {useActivities} = hooks;

const useCanInteractWithChat = () => {
  const activities = useActivities();

  const canInteract = useMemo(() => {
    const activitiesList = activities[0];

    if (activitiesList && activitiesList.length > 0) {
      const lastActivity = activitiesList[activitiesList.length - 1];
      if (
        lastActivity &&
        (lastActivity.from.role === "user" ||
          (lastActivity.from.role === "bot" &&
            lastActivity.text === "<p>Searching ...</p>"))
      ) {
        return false;
      }
    }
    return true;
  }, [activities]);

  return canInteract;
};

export default useCanInteractWithChat;

import React from "react";
import {RefreshIcon} from "../Icons/Icons";

function NewChatIcon({onClick, className}) {
  return (
    <span onClick={onClick} className={className}>
      <RefreshIcon />
    </span>
  );
}

export default NewChatIcon;

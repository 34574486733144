import React from "react";
import "./App.css";
import ChatComponent from "./Component/ChatComponent/ChatComponent";

class App extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      styleOptions: {
        emojiSet: true,
        backgroundColor: "var(--bg-gray)",
        hideUploadButton: true,
        avatarBorderRadius: "50%",
        avatarSize: 40,
        botAvatarBackgroundColor: "#FFF",
        botAvatarImage: "Icon2.png",
        botAvatarInitials: "IVECO",
        showAvatarInGroup: "status",

        bubbleBackground: "#FFF",
        bubbleBorderColor: "#666666",
        bubbleBorderRadius: "0px 15px 15px 15px",
        bubbleBorderStyle: "solid",
        bubbleBorderWidth: 0,
        bubbleTextColor: "Black",
        bubbleFromUserBackground: "var(--iveco-blue)",
        bubbleFromUserBorderColor: "#00137F",
        bubbleFromUserBorderRadius: "15px 15px 0px 15px",
        bubbleFromUserBorderWidth: 0,

        bubbleFromUserTextColor: "#FFF",
        messageActivityWordBreak: "break-word",
        timestampColor: "#858585",
        notificationText: "#FFF",
        failedConnectivity: "red",
        slowConnectivity: "red",

        sendBoxHeight: 60,
        sendBoxButtonColor: "var(--iveco-blue)",
        sendBoxButtonColorOnDisabled: "#CCC",
        sendBoxButtonColorOnFocus: "var(--iveco-blue)",
        sendBoxButtonColorOnHover: "var(--iveco-blue)",
      },
      sectionOptions: {
        boxShadow: "0px 2px 5px rgba(0,0,0,.2)",
        minWidth: "500px",
        borderRadius: "15px",
        width: "40%",
        height: "30%",
        margin: "auto",
      },
    };
  }

  render() {
    const {
      state: {styleOptions, sectionOptions},
    } = this;

    return (
      <section className="chatWrapper">
        {/* <header>Website page</header> */}
        <div className="react-container">
          <ChatComponent
            styleOptions={styleOptions}
            sectionOptions={sectionOptions}
          />
        </div>
        <div className="disclaimer">
        <p>We inform you that the data you provide will be used exclusively to generate answers and improve the quality of the service. 
            In filling out the questions and using the service, we ask you to refrain from entering personal data referring to you or third parties
            that are not necessary to achieve the purposes being processed and/or confidential data. Your personal data necessary to use the service and the information entered,
            will be collected and stored only for the time necessary to enable the service to function and to protect Iveco Group from legal claims.
            For further details on the processing of your personal data, please refer to the <a href="https://www.ivecogroup.com/legal/privacy_policy" target="_blank">Privacy Notice</a> posted on ivecogroup.com.
            For additional information about personal data processing, you can write an email to <a href="mailto:privacy-compliance@ivecogroup.com">privacy-compliance@ivecogroup.com</a>.
            Please be aware that the answers provided may be inaccurate, as they are processed by artificial intelligence systems and are therefore not to be considered as official statements of <a href="https://www.ivecogroup.com" target="_blank">Iveco Group</a>.
          </p>
        </div>
      </section>
    );
  }
}

export default App;
